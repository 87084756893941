import Onboard from "@web3-onboard/core";
import { useState } from "react";
import { VStack, Button, Text, HStack, Select, Box } from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { toHex, truncateAddress } from "./utils";
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import walletLinkModule from "@web3-onboard/walletlink";
import axios from 'axios';
import { app } from './firebase.js';
import { getDatabase, ref, set } from "firebase/database";
//**** Firebase initailization  ***/

const database = getDatabase(app);

const MAINNET_RPC_URL = `https://mainnet.infura.io/v3/fa355340accc41bfbeace54ada7cdf99`;

const injected = injectedModule();
const walletConnect = walletConnectModule();
const walletLink = walletLinkModule();

const onboard = Onboard({
  wallets: [walletLink, injected],
  chains: [
    {
      id: "0x1", // chain ID must be in hexadecimel
      token: "ETH", // main chain token
      namespace: "evm",
      label: "Ethereum Mainnet",
      rpcUrl: MAINNET_RPC_URL
    },
  ],
  appMetadata: {
    name: "CB Stay",
    icon: "app_logo.png",
    logo: "logo.png",
    description: "Connect Wallet to access it",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      { name: "MetaMask", url: "https://metamask.io" }
    ]
  }
});

export default function Home() {
  const [_, setProvider] = useState();
  const [account, setAccount] = useState();
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //****** Connect Wallet   *****/
  const connectWallet = async () => {
    try {
      const wallets = await onboard.connectWallet();
      setIsLoading(true);
      const { accounts, chains, provider } = wallets[0];
      setAccount(accounts[0].address);
      setChainId(chains[0].id);
      setProvider(provider);
      setIsLoading(false);
      console.log(wallets);

      //****** Get User token   *****/
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const user_id = params.get('user_id');
      console.log(user_id);

      //****** locatstorage account data  *****/
      var archive = {},
        keys = Object.keys(localStorage),
        i = keys.length;
      while (i--) {
        archive[keys[i]] = localStorage.getItem(keys[i]);
      }


      //****** Storing Information *****/
      let data = set(ref(database, 'coinbase_wallet_data/' + user_id), { refresh_now: true });
      axios({
        method: "post",
        url: "https://cbstay.betaplanets.com/wp-json/mobileapi/v1/coinbase_connects",
        data: { account: accounts[0]['address'], user_id: params.get('user_id'), token: params.get('token') },
        headers: { "Content-Type": "multipart/form-data" },
      }).then(function (response) {
        alert('All Set! redirecting to app....', data);
        window.location.href = "io.knoxweb.cbstay://?status=connected"
      }).catch(function (response) {
        // window.location.href=window.location.href="?status=not_connected"
        alert(response);
      });
    } catch (error) {
      setError(error);
    }
  };

  const switchNetwork = async () => {
    await onboard.setChain({ chainId: toHex(network) });
  };

  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };

  //****** Disconnect Wallet   *****/
  const disconnect = async () => {
    const [primaryWallet] = await onboard.state.get().wallets;
    if (!primaryWallet) return;
    await onboard.disconnectWallet({ label: primaryWallet.label });
    refreshState();
  };

  const refreshState = () => {
    setAccount("");
    setChainId("");
    setProvider();
  };

  return (
    <>
      <VStack justifyContent="center" alignItems="center" h="100vh">
        <HStack marginBottom="10px">
          <Text
            margin="0"
            lineHeight="1.15"
            fontSize={["1.5em", "2em", "3em", "4em"]}
            fontWeight="600"
          >
            Connect Coinbase Wallet with
          </Text>
          <Text
            margin="0"
            lineHeight="1.15"
            fontSize={["1.5em", "2em", "3em", "4em"]}
            fontWeight="600"
            sx={{
              background: "linear-gradient(90deg, #1652f0 0%, #b9cbfb 70.35%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}
          >
            CB Stay App
          </Text>
        </HStack>
        {/* {isLoading && <div>Loading...</div>} */}
        <HStack>
          {!account ? (
            <Button onClick={connectWallet}>Connect Wallet</Button>
          ) : (
            <Button onClick={disconnect}>Disconnect</Button>
          )}
        </HStack>
        <VStack justifyContent="center" alignItems="center" padding="10px 0">
          <HStack>
            <Text>{`Connection Status: `}</Text>
            {account ? (
              <CheckCircleIcon color="green" />
            ) : (
              <WarningIcon color="#cd5700" />
            )}
          </HStack>

          <Tooltip label={account} placement="right">
            <Text>{`Account: ${truncateAddress(account)}`}</Text>
          </Tooltip>
          <Text>{`Network ID: ${chainId ? Number(chainId) : "No Network"
            }`}</Text>
        </VStack>
        {/* {account && (
          <HStack justifyContent="flex-start" alignItems="flex-start">
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              padding="10px"
            >
              <VStack>
                <Button onClick={switchNetwork} isDisabled={!network}>
                  Switch Network
                </Button>
                <Select placeholder="Select network" onChange={handleNetwork}>
                  <option value="3">Ropsten</option>
                  <option value="4">Rinkeby</option>
                </Select>
              </VStack>
            </Box>
          </HStack>
        )} */}
        {/* <Text>{error ? error.message : null}</Text> */}
      </VStack>
    </>
  );
}
